import React from 'react'
import { ThemeProvider } from 'styled-components'
import '../styles/index.scss'

import { GlobalStyles } from '../styles/global'
import { lightTheme, darkTheme } from '../styles/themes'

const darkMode = true

export default ({ children }) => (
  <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
    <GlobalStyles />
    {children}
  </ThemeProvider>
)
